<template>
  <div id="app" class="overflow-x-hidden">
    <HeaderMenu />
    <!-- Always render the main content -->
    <router-view />
    <!-- Overlay the preloader when isLoading is true -->
    <PreloaderElement v-if="isLoading" @loaded="handleLoaded" />
    <FooterElement />
    <SpeedInsights />
  </div>
</template>

<script>
import HeaderMenu from "@/components/HeaderMenu.vue";
import FooterElement from "@/components/FooterElement.vue";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import PreloaderElement from "@/components/PreloaderElement.vue";
import { SpeedInsights } from "@vercel/speed-insights/vue";

export default {
  name: "App",
  components: {
    HeaderMenu,
    FooterElement,
    PreloaderElement,
    SpeedInsights,
  },
  setup() {
    // isLoading controls the visibility of the preloader overlay
    const isLoading = ref(true);
    const route = useRoute();

    // When the route changes, re-trigger the preloader animation.
    watch(
      () => route.fullPath,
      () => {
        isLoading.value = true;
      },
    );

    // When the preloader finishes its reveal animation, mark loading as done.
    const handleLoaded = () => {
      isLoading.value = false;
    };

    return { isLoading, handleLoaded };
  },
};
</script>

<style>
/* Ensure your preloader is absolutely positioned on top of the content */
#app {
  position: relative;
}
</style>
