<template>
  <footer class="footer flex items-center justify-center">
    <div class="footer-content p-6 text-center font-riposte font-thin">
      © {{ currentYear }} CG Labs. All rights reserved.
    </div>
  </footer>
</template>

<script setup>
const currentYear = new Date().getFullYear();
</script>

<style scoped></style>
