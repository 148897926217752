import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "LandingPage", // You might also want to rename this route if desired.
    component: () => import("@/views/LandingPage.vue"),
  },
  {
    path: "/blogs",
    name: "AllBlogPosts",
    component: () => import("@/views/AllBlogPosts.vue"),
  },
  {
    path: "/blog/:slug",
    name: "BlogPost",
    component: () => import("@/blog/BlogPostLayout.vue"),
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
