<template>
  <div class="overflow-hidden transition" ref="preloader">
    <!-- Centered logo container -->
    <div class="logo-container" ref="logo">
      <img src="@/assets/logo/labs-logomark.svg" alt="Logo" class="logo" />
      <!-- Loading bar added below the logo -->
      <div class="loading-bar-container">
        <div class="loading-bar" ref="loadingBar"></div>
      </div>
    </div>
    <div class="transition-row row-1">
      <div class="block"></div>
      <div class="block"></div>
      <div class="block"></div>
      <div class="block"></div>
      <div class="block"></div>
    </div>
    <div class="transition-row row-2">
      <div class="block"></div>
      <div class="block"></div>
      <div class="block"></div>
      <div class="block"></div>
      <div class="block"></div>
    </div>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from "vue";
import gsap from "gsap";

export default {
  name: "PreloaderElement",
  setup(_, { emit }) {
    const preloader = ref(null);
    const logo = ref(null);
    const loadingBar = ref(null);
    const ease = "power4.inOut";
    let ctx;

    function revealTransition() {
      return new Promise((resolve) => {
        // Query only within the preloader element
        const blocks = preloader.value.querySelectorAll(".block");
        gsap.set(blocks, { scaleY: 1 });
        gsap.to(blocks, {
          scaleY: 0,
          duration: 1,
          stagger: {
            each: 0.1,
            from: "start",
            grid: "auto",
            axis: "x",
          },
          ease: ease,
          onComplete: resolve,
        });
      });
    }

    // (Optional) For route transitions—can be removed if not needed
    function animateTransition() {
      return new Promise((resolve) => {
        const blocks = preloader.value.querySelectorAll(".block");
        gsap.set(blocks, { visibility: "visible", scaleY: 0 });
        gsap.to(blocks, {
          scaleY: 1,
          duration: 1,
          stagger: {
            each: 0.1,
            grid: [2, 5],
            axis: "x",
            from: "start",
          },
          ease: ease,
          onComplete: resolve,
        });
      });
    }

    // Helper: Wait for the browser to load all assets
    function waitForPageLoad() {
      return new Promise((resolve) => {
        if (document.readyState === "complete") {
          resolve();
        } else {
          window.addEventListener("load", resolve, { once: true });
        }
      });
    }

    onMounted(() => {
      // Fade in from bottom once while the page is loading
      gsap.from(logo.value, {
        opacity: 0,
        y: 20, // starting 20px below its final position; adjust as needed
        duration: 1,
        ease: "power1.inOut",
      });

      // Start the loading bar animation: animate its width from 0 to 90%
      const loadingBarAnim = gsap.to(loadingBar.value, {
        width: "90%",
        duration: 1.5,
        ease: "linear",
      });

      Promise.all([document.fonts.ready, waitForPageLoad()]).then(() => {
        ctx = gsap.context(() => {
          // Pause the loading bar progress animation in case it's still running
          loadingBarAnim.pause();

          // Create a timeline to complete the loading bar and fade out the logo & bar
          const tl = gsap.timeline({
            onComplete: () => {
              // After fading out, run the reveal animation on the blocks
              revealTransition().then(() => {
                const blocks = preloader.value.querySelectorAll(".block");
                gsap.set(blocks, { visibility: "hidden" });
                // Notify parent that the preloader has finished.
                emit("loaded");
              });
            },
          });
          tl.to(loadingBar.value, {
            width: "100%",
            duration: 0.5,
            ease: "linear",
          }).to([logo.value, loadingBar.value], {
            opacity: 0,
            duration: 0.5,
          });
        }, preloader.value);
      });
    });

    onBeforeUnmount(() => {
      if (ctx) {
        ctx.revert();
      }
    });

    return { preloader, logo, loadingBar, animateTransition };
  },
};
</script>

<style scoped>
.transition {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 100; /* Ensure the preloader overlays everything */
  pointer-events: none;
}

/* Center the logo container */
.logo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 300px;
  height: auto;
}

/* Loading bar styles */
.loading-bar-container {
  width: 250px; /* Match the logo width or adjust as needed */
  height: 5px; /* Adjust thickness as desired */
  background-color: black; /* Light background for contrast */
  margin-top: 24px;
  overflow: hidden;
  border-radius: 10px;
}

.loading-bar {
  width: 0;
  height: 100%;
  background-color: var(--color-company-500);
  border-radius: 10px;
  box-shadow: 0 0 15px var(--color-company-500);
}

.transition-row {
  flex: 1;
  display: flex;
}

.transition-row.row-1 .block {
  transform-origin: top;
}

.transition-row.row-2 .block {
  transform-origin: bottom;
}

.block {
  flex: 1;
  background-color: #0f0f0f;
  transform: scaleY(1);
  will-change: transform;
  visibility: visible;
}
</style>
