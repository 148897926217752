import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import { register } from "swiper/element/bundle";

// Register Swiper custom elements globally
register();

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAo7Jg0nHQuJdHsjD-oWt12BdPEUy7a304",
    authDomain: "website-15ecd.firebaseapp.com",
    projectId: "website-15ecd",
    storageBucket: "website-15ecd.firebasestorage.app",
    messagingSenderId: "410809557098",
    appId: "1:410809557098:web:44b090e1922a6a1fe2aa13",
    measurementId: "G-N3YR7ED9EK"
};

// Initialize Firebase
initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);

// Initialize Vue
const vueApp = createApp(App);
vueApp.use(router);
vueApp.mount("#app");
