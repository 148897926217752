<template>
  <header class="fixed top-0 left-0 z-50 w-full bg-black lg:bg-transparent">
    <!-- Main header grid -->
    <div
      class="flex items-center justify-between border-b px-6 py-4 md:px-10 md:py-6 lg:border-b-0 lg:px-12 xl:px-20"
    >
      <!-- Logo (grid item 1) -->
      <div class="flex justify-start">
        <router-link to="/">
          <img
            src="@/assets/logo/labs-logomark.svg"
            alt="Logo"
            class="h-8 lg:h-10"
          />
        </router-link>
      </div>
      <!-- Desktop Navigation (grid item 2) -->
      <nav
        class="hidden justify-center space-x-5 rounded-full border border-neutral-500 bg-gradient-to-t from-gray-100/[0.05] to-gray-100/[0.04] px-10 py-4 font-riposte text-base text-white backdrop-blur-2xl lg:flex xl:text-lg"
      >
        <a
          href="#reef-cam"
          class="transition-all duration-300 hover:text-company-500"
          @click="scrollToAnchor($event, '#reef-cam')"
          >ReefCam</a
        >
        <a
          href="#reef-app"
          class="transition-all duration-300 hover:text-company-500"
          @click="scrollToAnchor($event, '#reef-app')"
          >ReefApp</a
        >
        <a
          href="#photogrammetry"
          class="transition-all duration-300 hover:text-company-500"
          @click="scrollToAnchor($event, '#photogrammetry')"
          >Photogrammetry</a
        >
        <a
          href="#reef-map"
          class="transition-all duration-300 hover:text-company-500"
          @click="scrollToAnchor($event, '#reef-map')"
          >ReefMap</a
        >
        <a
          href="#community-science"
          class="transition-all duration-300 hover:text-company-500"
          @click="scrollToAnchor($event, '#community-science')"
          >Community Science</a
        >
        <!-- <a
          href="#blog"
          class="transition-all duration-300 hover:text-company-500"
          @click="scrollToAnchor($event, '#blog')"
          >Blog</a
        > -->
      </nav>
      <!-- Right side (grid item 3) -->
      <div class="flex justify-end">
        <!-- Desktop Contact Button -->
        <button
          class="hidden scale-101 cursor-pointer rounded-full bg-company-500 px-6 py-2 font-ocp text-black uppercase shadow-neon transition-all duration-500 lg:block lg:scale-100 lg:shadow-none lg:hover:scale-103 lg:hover:shadow-neon"
        >
          CONTACT US
        </button>
        <!-- Mobile / Tablet Hamburger -->
        <button
          @click="toggleMenu"
          class="text-white focus:outline-none lg:hidden"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8 w-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>
    </div>
    <!-- Mobile / Tablet Menu (visible when hamburger is clicked) -->
    <transition name="fade">
      <div
        v-if="isMenuOpen"
        class="absolute top-full left-0 flex h-svh w-full flex-col justify-between bg-black p-6 pb-26 lg:hidden"
      >
        <nav
          class="flex flex-col gap-4 font-lato text-3xl text-white uppercase md:text-5xl"
        >
          <a
            href="#reef-cam"
            class="nav-item"
            @click="scrollToAnchor($event, '#reef-cam')"
            >ReefCam</a
          >
          <a
            href="#reef-app"
            class="nav-item"
            @click="scrollToAnchor($event, '#reef-app')"
            >ReefApp</a
          >
          <a
            href="#photogrammetry"
            class="nav-item"
            @click="scrollToAnchor($event, '#photogrammetry')"
            >Photogrammetry</a
          >
          <a
            href="#reef-map"
            class="nav-item"
            @click="scrollToAnchor($event, '#reef-map')"
            >ReefMap</a
          >
          <a
            href="#community-science"
            class="nav-item"
            @click="scrollToAnchor($event, '#community-science')"
            >Community Science</a
          >
          <!-- <a
            href="#blog"
            class="nav-item"
            @click="scrollToAnchor($event, '#blog')"
            >Blog</a
          > -->
        </nav>
        <button
          @click="toggleMenu"
          class="mt-4 rounded-full bg-company-500 px-4 py-2 font-ocp text-black uppercase shadow-neon transition-all duration-500 hover:scale-105 hover:shadow-neon"
        >
          CONTACT US
        </button>
      </div>
    </transition>
  </header>
</template>

<script>
import Lenis from "lenis";

export default {
  name: "HeaderMenu",
  data() {
    return {
      isMenuOpen: false,
      lenis: null,
    };
  },
  mounted() {
    // Initialize Lenis for smooth scrolling.
    this.lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    });

    // Add a requestAnimationFrame loop so Lenis can update.
    const raf = (time) => {
      this.lenis.raf(time);
      requestAnimationFrame(raf);
    };
    requestAnimationFrame(raf);
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    scrollToAnchor(e, target) {
      e.preventDefault();
      const element = document.querySelector(target);
      if (element) {
        this.lenis.scrollTo(element);
      }
      if (this.isMenuOpen) {
        this.toggleMenu();
      }
    },
  },
};
</script>

<style scoped>
/* Fade transition for mobile menu */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
